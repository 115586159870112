import React, { Component } from 'react';
import DashboardAlert from "./dashboardAlert";

class AlertHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opcionMenuSelected:'Alerts',
        }

        this.showBox = this.showBox.bind(this);
    }

    showBox = data => () => {
        this.setState({opcionMenuSelected: data});
    }

    render() {
        return (
            <div className="dashboard-banking">

                <div className="p-grid overview-boxes">
                    <div className="p-col-12 p-md-4 p-lg-4" onClick={this.showBox('Alerts')} style={{cursor:'pointer'}}>
                        <div className="overview clearfix">
                            <div className="overview-icon">
                                <img src="assets/layout/images/dashboard-banking/icon-transfer.png" alt="avalon-layout"
                                     className="icon-transfer"/>
                            </div>

                            <div className="overview-text">
                                <span className="overview-title"></span>
                                <span className="overview-subtitle">Alerts</span>

                            </div>
                        </div>
                    </div>

                </div>

                {/* Page Content */}
                {(this.state.opcionMenuSelected==='Alerts') && <DashboardAlert />}
            </div>
        );
    }
}

export default AlertHome;
