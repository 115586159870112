import React from 'react';

import $ from 'jquery';
import "../../dist/mapplic/css/magnific-popup.css"
import "../../dist/mapplic/mapplic/mapplic.css"
import "../../dist/mapplic/js/jquery.mousewheel.js"
import "../../dist/mapplic/js/script.js"
import "../../dist/mapplic/mapplic/mapplic.js"
import PropTypes from "prop-types";

export class MapplicWrapper extends React.Component {
    constructor(props) {
        super(props);
    }

    static defaultProps = {
        worldMapID: "0",
        worldMap: null,
    }
    static propTypes = {
        worldMapID: PropTypes.string.isRequired,
        worldMap: PropTypes.object.isRequired,
    }

    componentDidMount() {
        this.$el = $(this.el);
        this.$el.mapplic();
        const id = "#mapplic_" + this.props.worldMapID;
        $([id]).mapplic({
            source: this.props.worldMap,
            height: 600,
            sidebar: false,
            marker: 'hidden',
            fullscreen: false,
            maxscale: 3,
            basecolor: 'rgba(255,255,255, 0.6)',
            bgcolor: '#333',
            bgcolor2: '#333',
            headingcolor: '#fff',
            textcolor: '#aaa'
        });
    }

    componentWillUnmount() {
        this.$el.mapplic('destroy');
    }

    componentDidUpdate(prevProps) {
        if (prevProps.children !== this.props.children) {
            this.$el.trigger("MapplicWrapper:updated");
        }
    }

    render() {
        return <div className="map-container" id={"mapplic_" + this.props.worldMapID}/>
    }
}
