import React, {Component} from 'react';
import {HeisenbergService} from "../../service/heisenberg/HeisenbergService";
import {Growl} from "primereact/growl";
import {ProgressSpinner} from "primereact/progressspinner";

import {Button} from "primereact/components/button/Button";
import {Toolbar} from "primereact/toolbar";
import {UsuarioService} from "../../service/login/UsuarioService";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {InputText} from "primereact/components/inputtext/InputText";
import {Dialog} from "primereact/components/dialog/Dialog";

export default class UnloadsCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addCardDisabled: '',
            displayAddUnloadCard:false,
            cardToken: '',
            amountCardToken: '',
            dataTableSelectValue: new FormData(),
            chargedYet: false,
            cardList: [],
            displayDelete: false,
            unloadCard: {},
        }
        this.usuarioService = new UsuarioService();
        this.apiHeisenberg = new HeisenbergService();
        this.callListPrincipal = this.callListPrincipal.bind(this);
        this.addUnloadCard = this.addUnloadCard.bind(this);
        this.confirmDeleteUnloadCard = this.confirmDeleteUnloadCard.bind(this);
    }

    componentDidMount() {
        this.callListPrincipal();
    }

    callListPrincipal() {
        this.usuarioService.getUnloadCardList().then(resp => {
            this.setState({
                cardList: resp,
                chargedYet: true
            });

        }).catch(error => {
            console.error("Error: ", error);
            this.growl.show({severity: 'success', summary: 'OK', detail: 'getAlldeposit service error'});
        });
    }

    addUnloadCard() {
        this.setState({displayAddUnloadCard: true});
    }
    confirmAddUnloadCard = () => {
        if(this.state.cardToken === '') {
            this.growl.show({severity: 'error', summary: 'error', detail: 'Card token is mandatory'});
        }else {
            this.setState({addCardDisabled: 'disabled'});
            const params = {
                card_token: this.state.cardToken,
                amount: this.state.amountCardToken
            };
            this.usuarioService.postCreateunloadcard(params).then(resp => {
                if (resp.status === 201) {
                    this.growl.show({severity: 'success', summary: 'OK', detail: 'add unloadCard'});
                    this.setState({
                        cardToken: '',
                        amountCardToken: '',
                    });
                    this.callListPrincipal();
                } else {
                    this.growl.show({severity: 'error', summary: 'error', detail: 'Card token does not exist'});
                }
                this.setState({addCardDisabled: '', displayAddUnloadCard: false});
            }).catch(error => {
                console.error("Error: ", error);
                this.growl.show({severity: 'error', summary: 'error', detail: 'Card token does not exist'});
                this.setState({addCardDisabled: '', displayAddUnloadCard: false});
            });
        }
    }

    deleteUnloadCard = (unloadCard) => {
        this.setState({
            unloadCard: unloadCard,
            displayDelete: true
        });
    }
    confirmDeleteUnloadCard = () => {
        this.setState({addCardDisabled: 'disabled'});
        this.usuarioService.postDeleteUnloadcard(this.state.unloadCard.id).then(resp => {
            this.setState({addCardDisabled: '', displayDelete: false});
            this.callListPrincipal();
        }).catch(error => {
            this.setState({addCardDisabled: ''});
            console.error("Error: ", error);
            this.growl.show({severity: 'error', summary: 'error', detail: 'create unload card service error'});
        });

    }
    onHide(name) {
        this.setState({
            [`${name}`]: false
        });
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    render() {
        const statusBodyTemplate = (rowData) => {
            return <Button icon="pi pi-trash" onClick={(e) => {
                this.deleteUnloadCard(rowData)
            }}/>;
        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="No" icon="pi pi-times" onClick={() => this.onHide(name)}
                            className="p-button-text p-button-secondary"/>
                    {name === "displayAddUnloadCard" &&
                    <Button label="Yes" icon="pi pi-check" disabled={this.state.addCardDisabled}
                            onClick={() => this.confirmAddUnloadCard()} autoFocus/>}
                    {name === "displayDelete" &&
                    <Button label="Yes" icon="pi pi-check" disabled={this.state.addCardDisabled}
                            onClick={() => this.confirmDeleteUnloadCard()} autoFocus/>}
                </div>
            );
        }
        return (
            <div className="pageWrapper">
                <Growl ref={(el) => this.growl = el}/>
                <div className="p-grid">
                    <div className="p-col-9 ta-right" style={{position: "absolute", marginTop: -60}}>
                        {this.state.chargedYet ? '' :
                            <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="#EEEEEE"
                                             animationDuration=".5s"/>}
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card mt-30">
                            <Toolbar className="p-mb-4 mb-10">
                                <Button label='Add Card' style={{marginLeft: 10}} onClick={this.addUnloadCard}/>
                            </Toolbar>
                            <DataTable value={this.state.cardList} header='Unloads Cards'
                                       reorderableColumns={true}
                                       selectionMode="single" paginator={true} rows={30}
                                       resizableColumns={true}
                                       selection={this.state.dataTableSelectValue}
                                       onSelectionChange={(e) => this.setState({dataTableSelectValue: e.value})}>
                                <Column field="card_token" header="Token" sortable={true} className="ta-right"/>
                                <Column field="amount" header="Amount" sortable={true} className="ta-right"/>
                                <Column field="account" header="Account" sortable={true} className="ta-right"/>
                                <Column field="created" header="Created" sortable={true} className="ta-right"/>
                            </DataTable>
                        </div>
                    </div>
                </div>

                <Dialog header="New UnloadCard" visible={this.state.displayAddUnloadCard} style={{width: '50vw'}}
                        footer={renderFooter('displayAddUnloadCard')}
                        onHide={() => this.onHide('displayAddUnloadCard')}>
                    <div className="p-fluid">
                        <div className="p-field p-grid">
                            <label htmlFor="cardToken" className="p-col-12 p-md-3 pt-14">Card Token</label>
                            <div className="p-col-12 p-md-9">
                                <InputText id="cardToken" name="cardToken" type='number' value={this.state.cardToken}
                                           onChange={this.handleChange}/>
                            </div>
                        </div>
                        <div className="p-field p-grid">
                            <label htmlFor="amountCardToken" className="p-col-12 p-md-3 pt-14">Amount</label>
                            <div className="p-col-12 p-md-9">
                                <InputText id="amountCardToken" name="amountCardToken" type='number'
                                           value={this.state.amountCardToken} onChange={this.handleChange}/>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog header="Confirm delete" visible={this.state.displayDelete} style={{width: '50vw'}}
                        footer={renderFooter('displayDelete')} onHide={() => this.onHide('displayDelete')}>
                    <p><span className="with-text80">Id</span> <b>{this.state.unloadCard.id}</b></p>
                    <p><span className="with-text80">Card token</span> <b>{this.state.unloadCard.card_token}</b></p>
                    <p><span className="with-text80">Account</span> <b>{this.state.unloadCard.account}</b></p>
                    <p><span className="with-text80">Created</span> <b>{this.state.unloadCard.created}</b></p>
                    <p><span className="with-text80">Amount</span> <b>{this.state.unloadCard.amount}</b></p>
                </Dialog>
            </div>
        );
    }

}
